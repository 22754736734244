import { Route, Routes } from "react-router-dom";
import video from "./assets/video/video-2.mp4";
import List from "./pages/List";
import Detail from "./pages/Detail";

import "./App.scss";
import { useState } from "react";
import useAuth from "./hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "./wallet";
import LoadingOverlay from "react-loading-overlay";

function App() {
  const { login, logout } = useAuth();
  const { account, active, chainId } = useWeb3React();

  const [loaded, setLoaded] = useState(false);

  const connectButtonClicked = async () => {
    if (active) {
      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        await switchNetwork(process.env.REACT_APP_CHAIN_ID_HEX);
      } else {
        logout();
      }
    } else {
      login();
    }
  };

  const accountEllipsis = account
    ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
    : null;

  return (
    <div className="App">
      <video className="video" playsInline autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
      <div className="app-content">
        <header className="header">
          <button className="btn-primary" onClick={connectButtonClicked}>
            {(function () {
              if (active) {
                return chainId !== Number(process.env.REACT_APP_CHAIN_ID)
                  ? "Switch network"
                  : accountEllipsis;
              } else {
                return "Connect Wallet";
              }
            })()}
          </button>
        </header>
        <main className="main">
          <Routes>
            <Route path="/" element={<Detail loaded={loaded} setLoaded={setLoaded} />} />
          </Routes>
        </main>
      </div>

      <div className={`dark-background ${loaded ? "d-block" : ""}`} style={{zIndex:999999999}}>
        <LoadingOverlay
          active={true}
          // spinner={<BounceLoader />}
          spinner={true}
          text="Waiting...">
          {/* <p>Some content or children or something.</p> */}
        </LoadingOverlay>
      </div>
    </div>
  );
}

export default App;
