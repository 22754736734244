import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import abi from "../utils/abi";
import erc20Abi from "../utils/erc20";
import { ethers } from "ethers";


const useApp = () => {
  const { account, chainId } = useWeb3React();

  const getTotalStaked = useCallback(async () => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const balance = await contract.totalStaked();
    return +ethers.utils.formatEther(balance);

  }, [])

  const getStakingInfo = useCallback(async () => {
    if (!account) return;
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const data = await contract.stakingInfo(account);
    return Number(data.stakingTime);
  }, []);

  const getApy = useCallback(async () => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const value = await contract.apy();
    return Number(value);
  }, []);

  const getParticipant = useCallback(async () => {
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider
    );

    const value = await contract.participant();
    return Number(value);
  }, []);

  const getEarnedBalance = useCallback(async () => {
    if (!account) return 0;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    const data = await contract.earnedBalance(account);

    return data.map(d => +ethers.utils.formatEther(d));

  }, [account, chainId])

  const stake = useCallback(async (amount) => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );
    const token = await contract.token();
    const tokenContract = new ethers.Contract(token, erc20Abi, provider.getSigner());
    const allowance = await tokenContract.allowance(account, process.env.REACT_APP_CONTRACT);
    if (Number(allowance) == 0) {
      const tx = await tokenContract.approve(process.env.REACT_APP_CONTRACT, '111111111111111111111111111111111111111111111111111');
      await tx.wait();
    }
    const stringAmount = ethers.utils.parseEther(amount + '');
    console.log(stringAmount);
    const tx = await contract.stake(stringAmount);
    await tx.wait();

  }, [account, chainId])

  const getBalance = useCallback(async () => {
    if (!account) return 0;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );
    const token = await contract.token();
    const tokenContract = new ethers.Contract(token, erc20Abi, provider);
    const balance = await tokenContract.balanceOf(account);
    return +ethers.utils.formatUnits(balance, '18');

  }, [account, chainId])

  const harvest = useCallback(async () => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    const tx = await contract.harvest();
    await tx.wait();

  }, [account, chainId])

  const withdraw = useCallback(async () => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT,
      abi,
      provider.getSigner()
    );

    const tx = await contract.withdraw();
    await tx.wait();

  }, [account, chainId])

  return { getTotalStaked, getApy, getParticipant, getEarnedBalance, stake, harvest, withdraw, getStakingInfo,getBalance }
};

export default useApp;
