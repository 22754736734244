import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import demo from "../../assets/img/staking.gif";
import box from "../../assets/img/box-main.png";
import logo from "../../assets/img/logo.svg";
import hammer from "../../assets/img/hammer.png";
import right from "../../assets/img/right.png";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import useApp from "../../hooks/useApp";
import { useWeb3React } from "@web3-react/core";
import { convertToCustomDate } from "../../utils/date";

function Detail({ setLoaded }) {
  const { account, chainId } = useWeb3React();
  const {
    getApy,
    getEarnedBalance,
    harvest,
    getParticipant,
    stake,
    getTotalStaked,
    withdraw,
    getStakingInfo,
    getBalance
  } = useApp();

  const [tokenBalance, setTokenBalance] = useState();
  const [tokenPrice, setTokenPrice] = useState(0.4);
  const [reload, setReload] = useState(new Date().getTime());
  const [apy, setApy] = useState(0);
  const [stakedBalance, setStakedBalance] = useState(0);
  const [earnedBalance, setEarnedBalance] = useState(0);
  const [participant, setParticipant] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [stakingTime, setStakingTime] = useState(0);

  const [stakeModalInput, setStakeModalInput] = useState(0);

  const [openStakeModal, setOpenStakeModal] = useState(false);

  const onOpenStakeModal = () => setOpenStakeModal(true);
  const onCloseStakeModal = () => setOpenStakeModal(false);

  const [openUnStakeModal, setOpenUnStakeModal] = useState(false);

  const onOpenUnStakeModal = () => setOpenUnStakeModal(true);
  const onCloseUnStakeModal = () => setOpenUnStakeModal(false);

  const getData = async () => {
    const apy = await getApy();
    const totalStaked = await getTotalStaked();
    const balance = await getEarnedBalance();
    const participant = await getParticipant();
    setApy(apy);
    setTotalStaked(totalStaked);
    setParticipant(participant);
    if (!account) return;
    const aStakingTime = await getStakingInfo();
    console.log(aStakingTime);
    const tokenBalance = await getBalance();
    setTokenBalance(tokenBalance);
    if(aStakingTime)
      setStakingTime(aStakingTime * 1000);
    else
      setStakingTime(0);
    setStakedBalance(balance[0]);
    setEarnedBalance(balance[1]);
  };

  const onStakeButtonClicked = async () => {
    try {
      setLoaded(true);
      await stake(stakeModalInput);
      setReload(new Date().getTime());
      onCloseStakeModal();
    } catch (error) {
    } finally {
      setLoaded(false);
    }
  };

  const onUnStakeButtonClicked = async () => {
    try {
      setLoaded(true);
      await withdraw();
      setReload(new Date().getTime());
      onCloseUnStakeModal();
    } catch (error) {
    } finally {
      setLoaded(false);
    }
  };

  const onHarvestButtonClicked = async () => {
    try {
      setLoaded(true);
      await harvest();
      setReload(new Date().getTime());
    } catch (error) {
    } finally {
      setLoaded(false);
    }
  };

  useEffect(() => {
    getData();
  }, [account, chainId, reload]);

  return (
    <div className="page">
      <div className="page-box-img">
        <img src={box} alt="" />
      </div>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <div className="page-content page-content-detail">
        <div className="page-content-header">
          <h2 className="btn-primary-reverse">$AZP Staking</h2>
          <div className="page-content-header-btn-group">
            <button
              className={`stake ${account ? "active" : ""}`}
              disabled={!account}
              onClick={onOpenStakeModal}
            >
              Stake
            </button>
            <button
              className={`stake ${account ? "active" : ""}`}
              disabled={!account}
              onClick={onOpenUnStakeModal}
            >
              Unstake
            </button>
            <button
              className={`stake ${account ? "active" : ""}`}
              disabled={!account}
              onClick={onHarvestButtonClicked}
            >
              Harvest
            </button>
          </div>
        </div>

        <Modal open={openUnStakeModal} onClose={onCloseUnStakeModal} center>
          <div className="modal-custom">
            <h3 className="modal-custom-title">Unstake $AZP</h3>
            <button
              className={`stake modal-submit-btn active`}
              onClick={onUnStakeButtonClicked}
            >
              UnStake
            </button>
          </div>
        </Modal>

        <Modal open={openStakeModal} onClose={onCloseStakeModal} center>
          <div className="modal-custom">
            <h3 className="modal-custom-title">STAKE $AZP</h3>
            <h3 className="modal-custom-subtitle">
              <span>Balance:</span> <span>{tokenBalance} $AZP</span>
            </h3>
            <form className="modal-custom-form">
              <input
                type="number"
                value={stakeModalInput}
                onChange={(e) => setStakeModalInput(e?.target?.value)}
              />
            </form>
            <button
              onClick={onStakeButtonClicked}
              className={`stake modal-submit-btn ${
                stakeModalInput > 0 && stakeModalInput <= tokenBalance ? "active" : ""
              }`}
              disabled={!stakeModalInput > 0 || stakeModalInput > tokenBalance}
            >
              Stake
            </button>
          </div>
        </Modal>

        <div className="page-content-main page-content-main-detail">
          <div className="page-detail-left">
            <img src={demo} alt="" />
            <span className="mining">
              <img src={hammer} alt="hammer" />
              Mining
            </span>
          </div>
          <div className="page-detail-right">
            <div className="page-detail-right-info">
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">
                  Total Stake
                </span>
                <span>{+totalStaked.toFixed(2)} $AZP</span>
              </div>
              {/* <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">TVL</span>
                <span>$ {+totalStaked.toFixed(2) * tokenPrice}</span>
              </div> */}
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">APY</span>
                <span>{+apy}%</span>
              </div>
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">
                  Participant
                </span>
                <span>{participant}</span>
              </div>
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">Your Stake</span>
                <span>{+stakedBalance.toFixed(2)} $AZP</span>
              </div>
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">
                  Your Stake Time
                </span>
                <span>
                  {stakingTime === 0
                    ? "--/--/---- --:--:--"
                    : convertToCustomDate(new Date(stakingTime))}
                </span>
              </div>
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">
                  Unlock Time
                </span>
                <span>
                  {stakingTime === 0
                    ? "--/--/---- --:--:--"
                    : convertToCustomDate(new Date(stakingTime + 259200000))}
                </span>
              </div>
              <div className="page-detail-right-info-item">
                <span className="page-detail-right-info-label">Reward</span>
                <span>{+earnedBalance.toFixed(2)} $AZP</span>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content-img-right">
          <img src={right} alt="right" />
        </div>
      </div>
    </div>
  );
}

export default Detail;
